<template>
  <div v-if="isAvailable">
    <VRow v-if="!isMobile">
      <VCol cols="8">
        <VTextField
          v-model="q"
          :label="$t('search.one')"
          variant="underlined"
          append-icon="search"
          clear-icon="close"
          clearable
          @input="onInput"
          @click:clear="q = ''"
        />
      </VCol>

      <VCol cols="4">
        <VSelect
          v-model="sortOption"
          label="Сортировать по умолчанию"
          variant="underlined"
          :items="sortOptions"
          item-text="title"
          :item-value="item => item"
          @change="filter"
        />
      </VCol>
    </VRow>

    <template v-else>
      <div
        class="d-flex mb-2"
      >
        <template v-if="!showSearch">
          <VBtn
            :title="sortOption.title"
            text
            class="d-block flex-shrink-1 justify-start px-0"
            @click="showSort = true"
          >
            <VIcon>sort</VIcon>
            <span class="text-truncate">{{ sortOption.title || 'По умолчанию' }}</span>
          </VBtn>

          <VSpacer />

          <VBtn
            v-if="!showSearch"
            text
            class="justify-end px-0"
            @click="showSearch = true"
          >
            <VIcon>search</VIcon>
          </VBtn>
        </template>

        <VTextField
          v-else
          v-model="q"
          autofocus
          :label="$t('search.one')"
          variant="underlined"
          prepend-inner-icon="search"
          append-outer-icon="send"
          clear-icon="close"
          clearable
          @click:append-outer="onBlur"
          @click:clear="q = ''"
          @blur="onBlur"
          @keyup.enter="onInput"
        />
      </div>

      <VDialog
        v-model="showSort"
        fullscreen
      >
        <VCard>
          <VCardTitle class="headline">
            Выберите вариант сортировки
            <VSpacer />
            <VBtn
              :title="$t('close.one')"
              icon
              @click="showSort=false"
            >
              <VIcon>close</VIcon>
            </VBtn>
          </VCardTitle>

          <VDivider />

          <VCardText class="text--primary">
            <VList>
              <VListItem
                v-for="option in sortOptions"
                :key="option.key"
                class="option"
                @click="setOption(option)"
              >
                {{ option.title }}
              </VListItem>
            </VList>
          </VCardText>
        </VCard>
      </VDialog>
    </template>
  </div>
</template>

<script>

export default {
  name: 'NewsFilter',

  props: {
    value: {
      type: Array,
      default: () => []
    },

  },

  data () {
    return {
      q: '',
      sortOption: {},
      sortOptions: [
        { key: 'default', title: 'По умолчанию' },
        { key: 'alphabet-asc', title: 'По наименованию (от А до Я)' },
        { key: 'alphabet-desc', title: 'По наименованию (от Я до А)' },
        { key: 'created-date-asc', title: 'По дате создания (по возрастанию)' },
        { key: 'created-date-desc', title: 'По дате создания (по убыванию)' },
        { key: 'status-unread-first', title: 'По статусу (сначала непрочитанные)' },
        { key: 'status-read-first', title: 'По статусу (сначала прочитанные)' },
      ],
      timeout: null,
      showSearch: false,
      showSort: false
    }
  },

  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.mobile
    },

    isAvailable () {
      return (this.isMobile && this.value?.length >= 3) || this.value?.length >= 6
    }
  },

  methods: {
    onInput () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.filter()
      }, 1000)
    },

    onBlur () {
      this.showSearch = false
      this.filter()
    },

    setOption (option) {
      this.sortOption = option
      this.showSort = false
      this.filter()
    },

    filter () {
      let filteredNews = this.value

      if (this.q) {
        filteredNews = filteredNews.filter(session => session.title.toLowerCase().includes(this.q.toLowerCase()))
      }

      switch (this.sortOption.key) {
        case 'alphabet-asc':
          filteredNews.sort((a, b) => a.title.localeCompare(b.title))
          break
        case 'alphabet-desc':
          filteredNews.sort((a, b) => b.title.localeCompare(a.title))
          break
        case 'created-date-asc':
          filteredNews.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
          break
        case 'created-date-desc':
          filteredNews.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          break
        case 'status-unread-first':
          filteredNews.sort((a, b) => Boolean(a.is_read) - Boolean(b.is_read))
          break
        case 'status-read-first':
          filteredNews.sort((a, b) => Boolean(b.is_read) - Boolean(a.is_read))
          break
        default:
          filteredNews.sort((a, b) => b.is_promoted - a.is_promoted)
          break
      }

      this.$emit('change', filteredNews || [])
    }
  }
}
</script>

<style lang="scss" scoped>
.option {
  border-radius: $border-radius-root;
  border: $border;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
</style>
