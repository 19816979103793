<template>
  <VCard
    v-if="singleNews"
    class="single-news-card"
    v-on="events"
  >
    <header>
      <VListItem dense>
        <VListItemContent>
          <VListItemTitle
            class="d-flex justify-space-between align-center text-uppercase"
          >
            {{ formatDate(singleNews.created_at) }}

            <span
              v-if="singleNews.is_promoted"
              class="accent--text"
            >
              Важно!
            </span>
          </VListItemTitle>
        </VListItemContent>
      </VListItem>
      <VDivider />
    </header>

    <div class="single-news-card__body">
      <VListItem v-if="singleNews.title">
        <VListItemContent>
          <VListItemTitle
            class="display-1 text-wrap"
            :title="singleNews.title"
          >
            {{ truncateText(singleNews.title, 108) }}
          </VListItemTitle>
        </VListItemContent>
      </VListItem>
      <VListItem v-if="singleNews.type">
        <VListItemContent>
          <VListItemTitle class="text-wrap info--text">
            {{ singleNews.type }}
          </VListItemTitle>
        </VListItemContent>
      </VListItem>
      <VListItem v-if="singleNews.body">
        <VListItemContent>
          <div v-html="truncateText(singleNews.body, 144)" />
        </VListItemContent>
      </VListItem>
    </div>

    <VCardActions
      tag="footer"
      class="actions"
    >
      <VBtn
        color="primary"
        :outlined="isRead"
        @click="goToSingleNews"
      >
        <VIcon left>
          play_arrow
        </VIcon>
        {{ isRead ? $t('read_again.one') : $t('read.one') }}
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
import { mapActions } from 'vuex'

import { UDate } from '@/utils/date'
import * as actions from '@/store/actions/types'

export default {
  name: 'SingleNewsCard',

  props: {
    singleNews: {
      type: Object,
      default: null
    },

    to: {
      type: [Object, String],
      default: ''
    }
  },

  computed: {
    events () {
      return this.to ? { click: this.goToSingleNews } : {}
    },

    isRead () {
      return this.singleNews.is_read
    }
  },

  methods: {
    formatDate: UDate.formatDate,

    ...mapActions({
      startSurvey: actions.START_SURVEY,
      continueSurvey: actions.CONTINUE_SURVEY,
    }),

    truncateText (text, maxLength) {
      // Проверяем, если текст уже меньше или равен максимальной длине
      if (text.length <= maxLength) {
        return text
      }

      // Обрезаем текст до максимальной длины
      const trimmedText = text.substr(0, maxLength)
      const lastSpaceIndex = trimmedText.lastIndexOf(' ')

      // Проверяем символ перед последним пробелом
      if (lastSpaceIndex > -1) {
        return trimmedText.substr(0, lastSpaceIndex) + '…'
      }

      // Если пробел не найден, просто обрезаем текст и добавляем многоточие
      return trimmedText + '…'
    },

    goToSingleNews () {
      this.$router.push(this.to).catch(() => {})
    },
  }
}
</script>

<style lang="scss">
.single-news-card {
  height: 100%;
  min-height: unset;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 0.5rem;
}

.single-news-card__body {
  flex-grow: 1;
}
</style>
