import { render, staticRenderFns } from "./SingleNewsCard.vue?vue&type=template&id=40d5d531&"
import script from "./SingleNewsCard.vue?vue&type=script&lang=js&"
export * from "./SingleNewsCard.vue?vue&type=script&lang=js&"
import style0 from "./SingleNewsCard.vue?vue&type=style&index=0&id=40d5d531&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports